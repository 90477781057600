import { tenantmgmtService } from '../../services';
import { request, success, failure, getMessage } from '../../helpers';
import { tenantMgmtTypes, userTypes } from '../types';
import { appActions } from './app.actions';
import { setAuthToken } from '../../helpers/request.helper';

const getAllTenants = (filterParams) => {

    return dispatch => {
        dispatch(request(tenantMgmtTypes.GET_ALL_TENANTS));

        tenantmgmtService.getAllTenants(filterParams)
            .then(
                data => {
                    dispatch(success(tenantMgmtTypes.GET_ALL_TENANTS_SUCCESS, { payload: data }));
                },
                ({ error, code }) => {
                    dispatch(failure(tenantMgmtTypes.GET_ALL_TENANTS_FAILURE, "Failed to Get the Tenants"));
                    getMessage(`sys-logs-api-failure`).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

export const tenantMgmtActions = {
    getAllTenants
}
import React, { useState } from 'react';
import axios from 'axios';
import DeleteTenantDialog from './DeleteTenantDialog'; // The dialog component
import DeleteButton from './../DeleteButton'; // The button component

const DeleteTenant = ({ tenant, onDeleteSuccess }) => {
  const [isDialogOpen, setDialogOpen] = useState(false);

  // Open the dialog
  const handleDeleteClick = () => {
    setDialogOpen(true);
  };

  // Close the dialog
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // Confirm deletion and call API
  const handleDeleteConfirm = () => {
    // axios
    //   .delete(`https://yourserver.com/api/tenants/${tenant.id}`) // Replace with your actual API URL
    //   .then(() => {
    //     onDeleteSuccess(tenant.id); // Notify parent that deletion was successful
    //     setDialogOpen(false); // Close the dialog after deletion
    //   })
    //   .catch((error) => {
    //     console.error('Error deleting tenant:', error);
    //   });

    // call an API to delete the tenant
  };

  return (
    <>
      {/* Delete Button to open the dialog */}
      <DeleteButton onDelete={handleDeleteClick} />

      {/* Delete Confirmation Dialog */}
      <DeleteTenantDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDeleteConfirm}
        tenantName={tenant.name}
      />
    </>
  );
};

export default DeleteTenant;

import React, { useEffect, useState, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Button, TextField, MenuItem, Select, FormControl, InputLabel, Grid } from '@mui/material';
import { tenantMgmtActions } from '../../redux/actions';
import dayjs from 'dayjs'
import DataTable from '../../components/DataTable';
import DateRangeFilter from '../../components/DateRangeFilter';
import CustomPagination from '../../components/CustomPagination';
import MultiFilter from '../../components/MultiFilter';
import { getMessage } from '../../helpers';
import { appActions } from '../../redux/actions';
import CopyButton from '../../components/CopyButton';
import EditButton from '../../components/EditButton';
import DeleteButton from '../../components/DeleteButton';
import SwitchButton from '../../components/SwitchButton';
import UpdateButton from '../../components/UpdateButton';
import DeleteTenant from './../../components/DeleteTenant'; // Import the delete handler component
import CreateTenant from '../../components/CreateTenant';

// set the default page size
const defaultPageSize = 100

// set the default last 7 days data
let defaultLast7DaysDate = new Date()
defaultLast7DaysDate.setDate(defaultLast7DaysDate.getDate() - 7)

const TenantManagement = () => {

  const dispatch = useDispatch()
  const filteredRows = useSelector(state => state?.tenantManagementReducer?.tenants)
  const [updatedTenants, setUpdatedTenants] = useState({});
  const [editingTenant, setEditingTenant] = useState(null);
  const [isAddEditFormVisible, setAddEditFormVisible] = useState(false);

  // define cloumn definitions
  const columns = [
    { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'mobile', headerName: 'Mobile', flex: 1 },
    { field: 'contactPerson', headerName: 'Contact Person', flex: 1 },
    {
      field: 'enabled', headerName: 'Enabled', flex: 1, renderCell: (params) => (
        <Fragment>
          <SwitchButton
            initialEnabled={params?.row?.enabled ?? false}
            onToggle={(newState) => {
              handleToggle(params?.row?.id, params?.row?.enabled, newState)
            }}
            tooltipText={"Enable/Disable Tenant"}
          />
          <UpdateButton
            isVisible={params?.row?.id in updatedTenants}
            onClick={() => handleUpdateTenantEnableState(params?.row?.id)}
          />
        </Fragment>
      ),
    },
    {
      field: 'actions', headerName: 'Actions', flex: 1, renderCell: (params) => (
        <Fragment>
          <CopyButton row={params.row} />
          <EditButton
            onEdit={handleEditTenant}
            item={params.row}
          />
          <DeleteTenant
            tenant={params.row}
            onDeleteSuccess={handleDeleteSuccess}
          />
        </Fragment>

      ),
    },
  ];

  useEffect(() => {
    getAllTenants()
  }, [])

  const getAllTenants = () => {
    dispatch(tenantMgmtActions.getAllTenants({}))
  }

  const handleToggle = (tenantId, initialState, newState) => {
    console.log(initialState, newState)

    if (initialState == newState) {
      // Remove the tenantId from updatedTenants
      setUpdatedTenants((prevState) => {
        let prevStateCopy = { ...prevState }; // Make a copy of the previous state
        delete prevStateCopy[tenantId]; // Remove the tenantId from the state
        return prevStateCopy; // Return the updated state
      });
    }
    else {
      setUpdatedTenants((prevState) => ({
        ...prevState,
        [tenantId]: newState,
      }));
    }
  };

  // Handle Update Click
  const handleUpdateTenantEnableState = (tenantId) => {
    // call an API to update enable and disbale tenant state
    // use updatedTenants state to get the new state to update for that tenant id
    
  };

  // Update tenant list after successful deletion
  const handleDeleteSuccess = () => {
    // deletion of Tenant is Succesful.. Get all the latest tenant from DB
    getAllTenants()
  };

  // Open dialog for adding new tenant
  const handleAddTenant = () => {
    setEditingTenant(null); // Reset editingTenant for adding new
    setAddEditFormVisible(true); // Open dialog
  };

  // Open dialog for editing existing tenant
  const handleEditTenant = (tenant) => {
    setEditingTenant(tenant); // Set tenant for editing
    setAddEditFormVisible(true); // Open dialog
  };

  const handleSuccess = () => {
    // Adding or Editing the Tenant is Successful, Get All Latest Tenant from DB
    getAllTenants()
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Fragment>
        <Grid container style={{ marginTop: '5px' }}>
          <Grid item xs={6}>
          </Grid>
          <Grid item xs={6} style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '20px'
          }}
          >
            <Button
              variant="contained"
              size='small'
              onClick={handleAddTenant}>
              Add New Tenant
            </Button>
          </Grid>
        </Grid>
        <DataTable
          rows={filteredRows && filteredRows.map((row, index) => ({
            ...row,
            id: index + 1,
          }))}
          columns={columns}
          pageSize={defaultPageSize}
          showPagination={false}
          columnVisibilityModel={{
            id: false
          }}
          styling={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#eeeeee',
              color: 'black',
              fontWeight: 'bold',
            },
          }}
        >
        </DataTable>
      </Fragment>
      <CreateTenant
        open={isAddEditFormVisible}
        onClose={() => setAddEditFormVisible(false)}
        tenantData={editingTenant}
        onSuccess={handleSuccess}
      />
    </Box>
  );
}

export default TenantManagement;
import React, { useState, useEffect } from 'react';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Switch, FormControlLabel, Box, CircularProgress } from '@mui/material';
import axios from 'axios';

const CreateTenant = ({ open, onClose, tenantData, onSuccess }) => {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    mobile: '',
    contactPerson: '',
    enabled: false,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (tenantData) {
      setFormValues(tenantData); // If editing, load the tenant data into the form
    } else {
      setFormValues({ name: '', email: '', mobile: '', contactPerson: '', enabled: false }); // Reset for new tenant
    }
  }, [tenantData]);

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // Handle switch toggle
  const handleToggle = (event) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      enabled: event.target.checked,
    }));
  };

  // Add new tenant
  const addTenant = async () => {
    try {
      setLoading(true);
      const response = await axios.post('/api/tenants', formValues);
      setLoading(false);
      onSuccess(response.data);
      onClose();
    } catch (error) {
      setLoading(false);
      setError('Failed to add tenant.');
    }
  };

  // Edit existing tenant
  const editTenant = async () => {
    try {
      setLoading(true);
      const response = await axios.put(`/api/tenants/${tenantData.id}`, formValues);
      setLoading(false);
      onSuccess(response.data);
      onClose();
    } catch (error) {
      setLoading(false);
      setError('Failed to update tenant.');
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (tenantData) {
      editTenant();
    } else {
      addTenant();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="md">
      <DialogTitle>{tenantData ? 'Edit Tenant' : 'Add Tenant'}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Tenant Name"
            name="name"
            value={formValues.name}
            onChange={handleChange}
            required
            fullWidth
          />
          <TextField
            label="Email"
            name="email"
            value={formValues.email}
            onChange={handleChange}
            required
            fullWidth
          />
          <TextField
            label="Mobile"
            name="mobile"
            value={formValues.mobile}
            onChange={handleChange}
            required
            fullWidth
          />
          <TextField
            label="Contact Person"
            name="contactPerson"
            value={formValues.contactPerson}
            onChange={handleChange}
            required
            fullWidth
          />
          <Box display="flex" alignItems="center" gap={1}>
            <Typography>Enable/Disable Tenant</Typography>
            <FormControlLabel
              control={<Switch checked={formValues.enabled} onChange={handleToggle} />}
              label={formValues.enabled ? 'Enabled' : 'Disabled'}
            />
          </Box>

          {error && <Box color="red">{error}</Box>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>Cancel</Button>
        <Button
          type="submit"
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : tenantData ? 'Update Tenant' : 'Add Tenant'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTenant;

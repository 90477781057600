import { tenantMgmtTypes } from "../types";
import { defaultPanasonicTenantDetails } from "../../utils/deafultTenant";
const initialState = {
    tenants:[],
    tenantsLoading: false,
    tenantError: false
};

export function tenantManagementReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case tenantMgmtTypes.GET_ALL_TENANTS:
            return {
                ...state,
                tenants: [],
                tenantsLoading: true,
                tenantError: false,
            };
        case tenantMgmtTypes.GET_ALL_TENANTS_SUCCESS:
            return {
                ...state,
                tenants: payload,
                tenantsLoading: false,
                tenantError: false,
            };
        case tenantMgmtTypes.GET_ALL_TENANTS_FAILURE:
            return {
                ...state,
                tenants: [],
                tenantsLoading: false,
                tenantError: true,
            }
        default:
            return state;
    }
}

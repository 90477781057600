import HomePage from '../pages/HomePage';
import LoginPage from '../pages/LoginPage'
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SettingsIcon from '@mui/icons-material/Settings';
//import SystemLogs from '../pages/SystemLogs';
import TenantManagement from '../pages/TenantManagement';
import SystemLogs from '../pages/SystemLogs';
import TenantLogs from '../pages/TenantLogs';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ListIcon from '@mui/icons-material/List';
import TenantUsers from '../pages/TenantUsers';

const routes = [
    {
        id: 1001,
        parent: null,
        icon: '',
        isPublicRoute: true,
        path: '',
        name: 'login',
        label: "Login",
        tooltip: "Login",
        component: LoginPage,
        exact: true,
        topMenu: false,
        subMenu: false
    },
    {
        id: 1021,
        icon: <DashboardIcon></DashboardIcon>,
        isPublicRoute: false,
        path: 'dashboard',
        name: 'dashboard',
        label: "Dashboard",
        tooltip: "Dashboard",
        component: HomePage,
        exact: true,
        topMenu: false,
        leftMenu: true,
        subMenu: false
    },
    {
        id: 1031,
        icon: <SettingsIcon></SettingsIcon>,
        isPublicRoute: false,
        path: '',
        name: 'System Configuration',
        label: "System Configuration",
        tooltip: "System Configuration",
        component: HomePage,
        exact: true,
        topMenu: false,
        leftMenu: true,
        subItems:[]
    },
    {
        id: 1041,
        icon: <SettingsIcon></SettingsIcon>,
        isPublicRoute: false,
        path: '',
        name: 'Tenant Management',
        label: "Tenant Management",
        tooltip: "Tenant Management",
        component: TenantManagement,
        exact: true,
        topMenu: false,
        leftMenu: true,
        subItems: [
            {
                id: 10411,
                icon: <ListIcon></ListIcon>,
                isPublicRoute: false,
                path: 'tenantmgmt',
                name: 'Tenants',
                label: "Tenants",
                tooltip: "Tenants",
                component: TenantManagement,
                exact: true,
                topMenu: false,
                leftMenu: true,
            },
            {
                id: 10412,
                icon: <ManageAccountsIcon></ManageAccountsIcon>,
                isPublicRoute: false,
                path: 'tenantusers',
                name: 'Tenant Users',
                label: "Tenant Users",
                tooltip: "Tenant Users",
                component: TenantUsers,
                exact: true,
                topMenu: false,
                leftMenu: true,
            }
        ]
    },
    {
        id: 1051,
        icon: <SettingsIcon></SettingsIcon>,
        isPublicRoute: false,
        path: 'clientconfig',
        name: 'Client Configuration',
        label: "Client Configuration",
        tooltip: "Client Configuration",
        component: HomePage,
        exact: true,
        topMenu: false,
        leftMenu: true,
        subItems: []
    },
    {
        id: 1061,
        icon: <ManageSearchIcon />,
        isPublicRoute: false,
        path: '',
        name: 'Audit Logs',
        label: "Audit Logs",
        tooltip: "Audit Logs",
        exact: true,
        topMenu: false,
        leftMenu: true,
        subItems: [
            {
                id: 10611,
                icon: <ManageSearchIcon />,
                isPublicRoute: false,
                path: 'systemlogs',
                name: 'System Logs',
                label: "System Logs",
                tooltip: "System Logs",
                component: SystemLogs,
                exact: true,
                topMenu: false,
                leftMenu: true,
                subMenu: false
            },
            {
                id: 10612,
                icon: <ManageSearchIcon />,
                isPublicRoute: false,
                path: 'tenantLogs',
                name: 'Tenant Logs',
                label: "Tenant Logs",
                tooltip: "Tenant Logs",
                component: TenantLogs,
                exact: true,
                topMenu: false,
                leftMenu: true,
                subMenu: false
            }
        ]
    }
];

export default routes;
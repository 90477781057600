import {
    systemLogsMessages,
    tenantLogsMessages,
    userMessages
    
} from "../constants/";

// Message dictionary
const messages = (key, item = null) => {
    const msg = {
        ...userMessages(item),
        ...systemLogsMessages(key),
        ...tenantLogsMessages(key)
    };

    return msg[key] || 'Unknown error, please try again later.';
}

export const getMessage = (key, item = null) => {
    return Promise.resolve(messages(key, item));
}

export const getMessageStr = (key, item = null) => {
    return messages(key, item);
}
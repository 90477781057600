import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import _ from 'lodash';
import {
    DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbar
} from '@mui/x-data-grid';
import axios from "axios";


export default function DataTable({
    rows,
    columns,
    pageSize,
    showPagination = true,
    columnVisibilityModel = {},
    styling = {}
}) {

    const [tableHeight, setTableHeight] = useState(0); // Default table height

    // Function to handle window resize and set table height dynamically
    const updateTableHeight = () => {
        const headerHeight = 100;  // Example header height
        const footerHeight = 100;  // Example footer height
        const availableHeight = window.innerHeight - headerHeight - footerHeight - 30;

        // Ensure there's always some minimal height for the table
        setTableHeight(availableHeight > 300 ? availableHeight : 300);
    };

    useEffect(() => {
        // Set the table height on initial render
        updateTableHeight();

        // Update the height dynamically when window is resized
        window.addEventListener('resize', updateTableHeight);

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', updateTableHeight);
    }, []);

    return (
        <React.Fragment>
            <DataGrid
                columnVisibilityModel={{
                    ...columnVisibilityModel
                }}
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                hideFooter
                sx={{maxHeight: tableHeight, overflowY: 'auto' , ...styling}}
                //sx={{ height: 'auto', ...styling }}
            />
        </React.Fragment>
    );
}
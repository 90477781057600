import { List } from '@mui/material';
import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box'
import { Collapse } from '@mui/material';
import routes from '../../routes/routes';
import { defaultSelectedMenu } from '../../config/appConfig';
import { appTypes } from '../../redux/types';
import './LeftNav.css';
import { useNavigate } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';

const LeftNav = ({ open }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const selectedLeftMenu = useSelector(state => state?.app?.selectedLeftMenu || defaultSelectedMenu.left);

    // State to manage which menu is expanded
    const [openMenus, setOpenMenus] = useState({});

    const [sideMenus] = useState(() => {
        return routes?.filter(r => r.leftMenu && !r.isPublicRoute).map((sMenu, index) => {
            sMenu.leftComponent = sMenu.component;
            return sMenu;
        });
    });

    const handleMenuItemClick = (event, index, menuItem) => {
        console.log(event, menuItem)
        dispatch({ type: appTypes.SELECT_LEFT_MENU, payload: menuItem.id })

        if (menuItem.subItems) {
            setOpenMenus((prevState) => ({
                ...prevState,
                [index]: !prevState[index], // Toggle the specific menu's state
            }));
        }
        else {
            navigate("/" + menuItem.path)
        }
    }

    // Toggle function for each menu
    // const handleSubMenuClick = (index) => {
    //     setOpenMenus((prevState) => ({
    //         ...prevState,
    //         [index]: !prevState[index], // Toggle the specific menu's state
    //     }));
    // };

    return (
        <List>
            {sideMenus.map((menuItem, index) => (
                <Fragment key={index}>
                    <ListItem
                        key={menuItem.name}
                        disablePadding
                        sx={{
                            display: 'block'
                        }}
                        selected={menuItem.id == selectedLeftMenu}
                        onClick={(event) => {
                            handleMenuItemClick(event, index, menuItem)
                        }}
                        className={menuItem.id == selectedLeftMenu ? "active-menu" : ""}
                    >
                        <ListItemButton
                            sx={{
                                minHeight: 62,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                                className={menuItem.id == selectedLeftMenu ? "active-icon" : ""}

                            >
                                {menuItem.icon}
                            </ListItemIcon>
                            <ListItemText primary={menuItem.label} sx={{ opacity: open ? 1 : 0 }} />

                            <Box style={{ marginLeft: '20px' }}>
                                {menuItem.subItems ? (
                                    openMenus[index] ? <ExpandLess /> : <ExpandMore />
                                ) : null}
                            </Box>
                        </ListItemButton>
                    </ListItem>

                    {/* Submenu Items */}
                    {menuItem.subItems && (
                        <Collapse in={openMenus[index]} timeout="auto" unmountOnExit>
                            <List 
                                component="div" 
                                disablePadding
                            >
                                {menuItem.subItems.map((subItem, subIndex) => (
                                    <ListItemButton
                                        sx={{ pl: 4 }}
                                        key={subIndex}
                                        onClick={(event) => {
                                            handleMenuItemClick(event, subIndex, subItem)
                                        }}
                                        selected={subItem.id == selectedLeftMenu}
                                        className={subItem.id == selectedLeftMenu ? "active-menu" : ""}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                            className={subItem.id == selectedLeftMenu ? "active-icon" : ""}
                                        >
                                            {subItem.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={subItem.name} />
                                    </ListItemButton>
                                ))}
                            </List>
                        </Collapse>
                    )}
                </Fragment>
            ))}
        </List>
    )
};

LeftNav.propTypes = {};

LeftNav.defaultProps = {};

export default LeftNav;

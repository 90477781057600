import React from 'react';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const EditButton = ({ onEdit, item }) => {
    return (
        <IconButton onClick={() => onEdit(item)}>
            <EditIcon
                color="primary"
                size='small'
            />
        </IconButton>
    );
};

export default EditButton;

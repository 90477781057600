import { API } from '.'
import { handleError, handleResponse } from '../helpers';
import { API_URL, clientId } from '../config/appConfig';

const getAllTenants = async (filterparams) => {
    return API.get(`${API_URL.idp}/system/tenant`, "all_tenants")
        .then(handleResponse)
}

export const tenantmgmtService = {
    getAllTenants
};
import React, { useState } from "react";
import { Switch, Tooltip } from "@mui/material";

const SwitchButton = ({ initialEnabled, onToggle, tooltipText = "" }) => {
  const [enabled, setEnabled] = useState(initialEnabled);

  const handleToggle = () => {
    const newState = !enabled;
    setEnabled(newState);
    onToggle(newState); // Notify parent component of the state change
  };

  return (

    <Tooltip title={tooltipText}>
      <Switch
        checked={enabled}
        onChange={handleToggle}
        color={enabled ? "primary" : "default"}
      />
    </Tooltip>
  );
};

export default SwitchButton;

export const APP_URL = process.env.REACT_APP_API_URL || "https://api-dev1.lifestyleindia.net"
export const AUTH_URL = process.env.REACT_APP_AUTH_URL || "https://auth-dev.lifestyleindia.net"
export const IDP_URL = process.env.REACT_APP_IDP_URL
export const PANASONIC_CLIENT_ID = process.env.REACT_APP_CLIENT_ID_PANASONIC || "66307010ed750bd75b804feb";

const APP_URL_VERSION = 'v1'
const AUTH_URL_VERSION = "v2";
export const API_URL = {
    idpAuth: IDP_URL + "/api/identity/v1",
    idp: IDP_URL + "/api/identity/v1",
    auth: AUTH_URL + "/simplifi/" + AUTH_URL_VERSION + "/userManagement",
    device: APP_URL + "/simplifi/" + APP_URL_VERSION + "/deviceManagement",
};

export const defaultSelectedMenu = {
    top: null,
    left: 1021
}

export const disableDevFeatures = {
    itemId: process.env.REACT_APP_ENV === 'dev' ? false : true,
    tooltip:true
}

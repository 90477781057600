
import { systemLogService } from '../../services';
import { request, success, failure, getMessage } from '../../helpers';
import { ten, tenantLogsTypes, userTypes } from '../types';
import { appActions } from './app.actions';
import { setAuthToken } from '../../helpers/request.helper';
import { tenantLogService } from '../../services/tenantlogs.service';

const getTenantLogs = (filterParams) => {

    return dispatch => {
        dispatch(request(tenantLogsTypes.GET_TENANT_LOGS_REQUEST));

        tenantLogService.getTenantLogs(filterParams)
            .then(
                data => {
                    if (data && data.hasOwnProperty('message')) {
                        dispatch(failure(tenantLogsTypes.GET_TENANT_LOGS_FAILURE, data?.message.toString() ?? "Failed to Get the Tenant Logs Data"));
                        getMessage(`tenant-logs-no-data-found`).then((msg) => {
                            dispatch(appActions.apiErrorMessage(msg));
                        })
                        return
                    }
                    else {
                        dispatch(success(tenantLogsTypes.GET_TENANT_LOGS_SUCCESS, { payload: data }));
                    }
                },
                ({ error, code }) => {
                    dispatch(failure(tenantLogsTypes.GET_TENANT_LOGS_FAILURE, "Failed to Get the Tenant Logs Data"));
                    getMessage(`tenant-logs-api-failure`).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

export const tenantLogsActions = {
    getTenantLogs
}
import React from "react";
import { Button } from "@mui/material";

const UpdateButton = ({ onClick, isVisible }) => {
  if (!isVisible) return null;

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      sx={{ backgroundColor: "orange", color: "white" }}
    >
      Update
    </Button>
  );
};

export default UpdateButton;

import { Routes, Route, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import AccountAccess from "../pages/AccountLinking/AccountAccess"
import routes from './routes';
import PrivateRoute from './PrivateRoute';
import NotFoundPage from "../pages/NotFoundPage";
import { useSelector } from 'react-redux'
import StyledSnackBar from './../components/StyledSnackBar';
import MainLayout from "../layouts/MainLayout";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { CssBaseline } from "@mui/material";

const RootRouter = () => {

    const isAuthenticated = useSelector(state => state?.authentication?.loggedIn);
    //const isAuthenticated = true
    const app = useSelector((state) => state?.app);
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const navigate = useNavigate()

    const defaultTheme = createTheme(
        {
            palette: {
                primary: {
                    main: "#0288d1",
                    contrastText: "#FFF"
                },
                secondary: {
                    main: "#CCC"
                },
                text: {
                    primary: "#23315A",
                    secondary: "#000",
                },
                background: {
                    default: '#ffffff', // Default background color for the body
                    paper: '#ffffff',   // Background color for Paper components or surfaces
                },
            },
            typography: {
                fontFamily: 'sans-serif'
            },
        }
    );

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/dashboard')
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (app?.error || app?.message) {
            setOpenSnackBar(true);
        }
        else {
            if (openSnackbar) {
                setOpenSnackBar(false)
            }
        }
    }, [app?.error, app?.message]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    return (
        <main>
            <Routes>
                <Route path='/idpLogin' element={<AccountAccess showLoginPage={true} showAuthorizationPage={false} />} />
                <Route path='/idpAuthorization' element={<AccountAccess showLoginPage={false} showAuthorizationPage={true} />} />
                {routes?.filter(r => r.isPublicRoute)?.map(({ component: Component, path, exact }) => (
                    <Route
                        path={`/${path}`}
                        key={path}
                        exact={exact}
                        element={<Component />}
                    />
                ))}
                <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
                    {routes?.filter(r => !r.isPublicRoute)?.map(({ component: Component, path, exact, subItems }) => (
                        subItems && subItems.length > 0 ?
                            subItems?.filter(rs => !rs.isPublicRoute)?.map(({ component: Component, path, exact }) => (
                                <Route path={`/${path}`} key={path} exact={exact} element={
                                    <ThemeProvider theme={defaultTheme}>
                                        <CssBaseline />
                                        <MainLayout>
                                            <Component />
                                        </MainLayout>
                                    </ThemeProvider>
                                }>
                                </Route>
                            ))
                            :
                            <Route path={`/${path}`} key={path} exact={exact} element={
                                <ThemeProvider theme={defaultTheme}>
                                    <CssBaseline />
                                    <MainLayout>
                                        <Component />
                                    </MainLayout>
                                </ThemeProvider>
                            }>
                            </Route>
                    ))}
                </Route>
                <Route path="*" exact={true} component={NotFoundPage} />
            </Routes>

            {/*SnackBar Messaging Handler*/}
            {app.messageType === "success" ? (
                <StyledSnackBar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    data={{ "type": app.messageType, "message": app.message }}
                />
            ) : (
                <StyledSnackBar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    data={{ "type": app.messageType, "message": app.message }}
                />
            )}
        </main>
    )
}

RootRouter.propTypes = {};

RootRouter.defaultProps = {};

export default RootRouter;
import React from 'react';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const DeleteButton = ({ onDelete, item="" }) => {
    return (
        <IconButton onClick={() => onDelete(item)}>
            <DeleteIcon
                color="primary"
                size='small'
            />
        </IconButton>
    );
};

export default DeleteButton;
